<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-checkbox v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="parentName" @md-selected="getParentGroupSelected" :md-options="parentGroups" @md-changed="getParentGroups"  @md-opened="getParentGroups" :class="{'md-invalid': submitted && $v.parentId.$error }">
                                    <label>Nhóm phương tiện cha<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.groupName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.parentId.isSelected">Vui lòng chọn nhóm phương tiện cha</span>
                                </md-autocomplete>
                                <md-button @click="openParentGroup()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="brandName" @md-selected="getBrandSelected" :md-options="brands" @md-changed="getBrands"  @md-opened="getBrands" :class="{'md-invalid': submitted && $v.entity.brandId.$error }">
                                    <label>Nhãn hiệu<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.brandName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.brandId.isSelected">Vui lòng chọn nhãn hiệu</span>
                                </md-autocomplete>
                                <md-button @click="openBrand()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="radio-group">
                                <label>Chủ phương tiện<span class="label-require">(*)</span></label>
                                <div class="form-control">
                                    <md-radio @change="changeOwner(true)" v-model="entity.isOwner" :value="true">PPL</md-radio>
                                    <md-radio @change="changeOwner(false)" v-model="entity.isOwner" :value="false">Chủ khác</md-radio>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="ownerName" @md-selected="getOwnerSelected" :md-options="owners" @md-changed="getOwners"  @md-opened="getOwners" :class="{'md-invalid': submitted && $v.entity.ownerId.$error }">
                                    <label>Chủ phương tiện<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.ownerId.isSelected">Vui lòng chọn chủ phương tiện</span>
                                </md-autocomplete>
                                <md-button @click="openOwner()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="groupName" @md-selected="getGroupSelected" :md-options="groups" @md-changed="getGroups"  @md-opened="getGroups" :class="{'md-invalid': submitted && $v.entity.groupId.$error }">
                                    <label>Nhóm phương tiện<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.groupName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.groupId.isSelected">Vui lòng chọn nhóm phương tiện</span>
                                </md-autocomplete>
                                <md-button @click="openGroup()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.branchId.$error }">
                                <label for="branchId">Chi nhánh<span class="label-require">(*)</span></label>
                                <md-select v-model="entity.branchId" name="branchId">
                                    <md-option :value="0">Chọn chi nhánh</md-option>
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                                <span class="md-error" v-if="submitted && !$v.entity.branchId.isSelected">Vui lòng chọn chi nhánh</span>
                            </md-field>
                            
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="locationName" @md-selected="getLocationSelected" :md-options="locations" @md-changed="getLocationList"  @md-opened="getLocationList">
                                    <label>Vị trí</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openLocation()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="shaftNumber">Số trục</label>
                                        <md-input name="shaftNumber" v-model="entity.shaftNumber"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="tire">Thông số lốp</label>
                                        <md-input name="tire" v-model="entity.tire"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.licensePlate.$error }">
                                        <label for="licensePlate">Biển số<span class="label-require">(*)</span></label>
                                        <md-input name="licensePlate" v-model="entity.licensePlate"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.licensePlate.required">Vui lòng nhập biển số</span>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.vehicleCode.$error }">
                                        <label for="vehicleCode">Mã phương tiện<span class="label-require">(*)</span></label>
                                        <md-input name="vehicleCode" v-model="entity.vehicleCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.vehicleCode.required">Vui lòng nhập mã phương tiện</span>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="chassisNo">Số khung</label>
                                        <md-input name="chassisNo" v-model="entity.chassisNo"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="engineNo">Số máy</label>
                                        <md-input name="engineNo" v-model="entity.engineNo"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="manufacturedYear">Năm sản xuất</label>
                                        <md-input name="manufacturedYear" v-model="entity.manufacturedYear"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="expireYear">Niên hạn</label>
                                        <md-input name="expireYear" v-model="entity.expireYear"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="dimension">Kích thước(dài/rộng/cao)</label>
                                        <md-input name="dimension" v-model="entity.dimension"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="kerbMass">KL bản thân(kg)</label>
                                        <md-input name="kerbMass" v-model="entity.kerbMass"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="tire">Công thức bánh</label>
                                        <md-input name="tire" v-model="entity.formulaTire"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="tire">Vết bánh xe</label>
                                        <md-input name="tire" v-model="entity.vetTire"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="tire">Kích thước bao</label>
                                        <md-input name="tire" v-model="entity.bagSize"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="tire">Chiều dài cơ sở</label>
                                        <md-input name="tire" v-model="entity.baseLong"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="maxOutput">Công suất(KW/VP)</label>
                                        <md-input name="maxOutput" v-model="entity.maxOutput"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="tire">Số người được phép chở</label>
                                        <md-input name="tire" v-model="entity.maxPerson"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="feature">Đặc tính</label>
                                        <md-input name="feature" v-model="entity.feature"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="payload">KL tổng chở(kg)</label>
                                        <md-input name="payload" v-model="entity.payload"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="tire">KL trên mâm kéo TK/TGGT</label>
                                <md-input name="tire" v-model="entity.massTray"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="tire">KL toàn bộ theo TK/TGGT</label>
                                <md-input name="tire" v-model="entity.massTotal"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="tire">KL kéo theo TK/CP TGGT</label>
                                <md-input name="tire" v-model="entity.massPull"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-field :class="{'md-invalid': submitted && $v.entity.status.$error }">
                                    <label>Trạng thái</label>
                                    <md-select v-model="entity.status">
                                        <md-option v-for="item in statusList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                    </md-select>
                                    <span class="md-error" v-if="submitted && !$v.entity.status.isSelected">Vui lòng chọn trạng thái</span>
                                </md-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-tabs>
                                <md-tab id="tab-mooc" class="tab-content" md-label="Danh sách mooc">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addMooc()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th>
                                                            <th style="width:40%;">Mã mooc</th> 
                                                            <th style="width:40%;">Biển số</th> 
                                                            <th style="width:15%">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.moocs" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.vehicleCode" class="form-control" type="text">
                                                                    <md-button @click="openMooc(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm mooc</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                {{ item.licensePlate }}
                                                            </td>
                                                            <td class="grid-action">
                                                                <a @click="delMooc(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-tire" class="tab-tire" md-label="Lốp xe">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addTireRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th>
                                                            <th style="width:15%;">Vị trí</th> 
                                                            <th style="width:20%;">Tên lốp</th> 
                                                            <th style="width:10%;">Lốp số</th> 
                                                            <th style="width:10%;">Mã lốp</th> 
                                                            <th style="width:15%;">Ngày thêm</th> 
                                                            <th style="width:10%;">Tình trạng</th> 
                                                            <th style="width:10%;">Km thay thế</th> 
                                                            <th style="width:120px">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.tireList" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.type.name" class="form-control" type="text">
                                                                    <md-button @click="openTireCode(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm vị trí</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.product.productName" class="form-control" type="text">
                                                                    <md-button @click="openTire(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm lốp xe</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.position" class="form-control" type="text">
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.tireCode" class="form-control" type="text">
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <md-datepicker v-model="item.createdDate" md-immediately :md-model-type="String"></md-datepicker>
                                                                </div>
                                                            </td> 
                                                            <td class="center">{{item.product.tireStatus}}%</td>
                                                            <td class="center">{{formatNumber(item.product.kmReplace)}}</td> 
                                                            <td class="grid-action">
                                                                <a @click="delTireRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-registry" class="tab-content" md-label="Đăng kiểm">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addRegistryRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th>
                                                            <th style="width:28%;">Số quản lý</th> 
                                                            <th style="width:30%;">Phí đăng kiểm</th> 
                                                            <th style="width:15%;">Ngày đăng kiểm</th> 
                                                            <th style="width:15%;">Ngày hết hạn</th>
                                                            <th style="width:120px">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.registryList" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.registryCode" :class="{'is-error': $v.entity.registryList.$each[index].registryCode.$error}" class="form-control" type="text">
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number v-model="item.registryFee" v-bind="currencyF" class="form-control currency"></number>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <md-datepicker v-model="item.registryDate" :class="{'is-error': $v.entity.registryList.$each[index].registryDate.$error}" md-immediately :md-model-type="String"></md-datepicker>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <md-datepicker v-model="item.expiryDate" :class="{'is-error': $v.entity.registryList.$each[index].expiryDate.$error}" md-immediately :md-model-type="String"></md-datepicker>
                                                                </div>
                                                            </td> 
                                                            <td class="grid-action">
                                                                <a @click="delRegistryRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-insurance" class="tab-content" md-label="Bảo hiểm">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addInsuranceRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th>
                                                            <th style="width:20%;">Số quản lý</th> 
                                                            <th style="width:25%;">Phí bảo hiểm</th> 
                                                            <th style="width:15%;">Ngày đăng kiểm</th> 
                                                            <th style="width:15%;">Ngày hết hạn</th>
                                                            <th style="width:15%;">Loại bảo hiểm</th>
                                                            <th style="width:120px">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.insuranceList" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.insuranceCode" :class="{'is-error': $v.entity.insuranceList.$each[index].insuranceCode.$error}" class="form-control" type="text">
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number v-model="item.insuranceFee" v-bind="currencyF" class="form-control currency"></number>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <md-datepicker v-model="item.registryDate" :class="{'is-error': $v.entity.insuranceList.$each[index].registryDate.$error}" md-immediately :md-model-type="String"></md-datepicker>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <md-datepicker v-model="item.expiryDate" :class="{'is-error': $v.entity.insuranceList.$each[index].expiryDate.$error}" md-immediately :md-model-type="String"></md-datepicker>
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <md-field>
                                                                    <md-select v-model="item.typeCode" name="typeInsurance" id="typeInsurance">
                                                                        <md-option v-for="obj in typeInsurance" :key="'typeInsurance-' + obj.code" :value="obj.code">{{obj.name}}</md-option>
                                                                    </md-select>
                                                                </md-field>
                                                            </td>
                                                            <td class="grid-action">
                                                                <a @click="delInsuranceRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-files" class="tab-content" md-label="Ảnh phương tiện">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <upload ref="upload" :updateFileInParent="updateFile"/>
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                            </md-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <md-dialog-alert :md-active.sync="dataCheck" md-title="Thông báo!" md-content="Bạn phải thêm mới phương tiện trước" />
        <locationList ref="locationList" @close="closeLocation"/>
        <brandList ref="brandList" @close="closeBrand"/>
        <ownerList ref="ownerList" title="chủ phương tiện" :type="companyType" @close="closeOwner"/>
        <tireList ref="tireList" :typeCode="tireCode" title="lốp xe" @close="closeTire"/>
        <tireCodeList ref="tireCodeList" :group="tirePosition" title="vị trí lốp xe" @close="closeTireCode"/>
        <parentGroup ref="parentGroup" @close="closeParentGroup"/>
        <childGroup ref="childGroup" @close="closeGroup"/>
        <moocList ref="moocList" @close="closeMooc"/>
    </div>
</template>
<script>
    import locationService from '../../../api/locationService';
    import branchService from '../../../api/branchService';
    import vehicleGroupService from '../../../api/vehicleGroupService'; 
    import vehicleService from '../../../api/vehicleService';
    import brandService from '../../../api/brandService';
    import companyService from '../../../api/companyService';
    import typeService from '../../../api/typeService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import brandList from '../../../components/popup/_BrandList.vue';
    import ownerList from '../../../components/popup/_OwnerVehicleList.vue';
    import tireList from '../../../components/popup/_ProductList.vue';
    import tireCodeList from '../../../components/popup/_TypeList.vue';
    import common from '../../../mixins'; 
    import parentGroup from '../../../components/popup/_VehicleParentGroup.vue';
    import childGroup from '../../../components/popup/_VehicleGroup.vue';
    import upload from '../../../components/Upload.vue';
    import locationList from '../../../components/popup/_LocationList.vue';
    import moocList from '../../../components/popup/_VehicleList.vue';

    export default ({
        components: {
            upload,
            brandList,
            ownerList,
            tireList,
            tireCodeList,
            parentGroup,
            childGroup,
            locationList,
            moocList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật phương tiện'
        },
        data() {
            return {
                title: '',
                id: 0,
                dataCheck: false,
                saveAndCreate: false,
                submitted: false,
                loadding: false,
                groupInsurance: common.groupCode.insurance,
                companyType: common.companyType.transport,
                group: common.groupCode.vehicleStatus,
                tirePosition: common.groupCode.tire,
                tireCode: common.productType.tire,
                entity: { id: 0, moocs: [], feature: '', massPull: '', massTotal: '', massTray: '', maxPerson: '', baseLong: '', bagSize: '', vetTire: '', formulaTire: '', branchId: 0, type: 1, expireYear: null, dimension: null, kerbMass: null, payload: null, tire: null, maxOutput: null, shaftNumber: null, chassisNo: null, engineNo: null, manufacturedYear: null, groupId: 0,  ownerId: 0, brandId: 0, vehicleCode: '', licensePlate: '', isOwner: true, status: 1, files: [], tireList: [], insuranceList: [], registryList: [] },
                brandName: '',
                brands: [],
                ownerName: '',
                owners: [],
                parentGroups: [],
                parentName: '',
                parentId: 0,
                groups: [],
                groupName: '',
                typeInsurance: [],
                currencyF: common.currencyF,
                branchs: [],
                statusList: [ { id: 1, text: "Hoạt động" }, { id: 2, text: "Sửa chữa" }, { id: 0, text: "Tạm dừng" } ],
                locationName: '',
                locations: [],
            }
        },
        created(){
            this.getTypes();
            this.getOwner();
            this.getBranchs();
            this.id = parseInt(this.$route.params.id);
            if(this.id > 0){
               this.title = 'Cập nhật phương tiện';
               this.getById();
            }
            else{
               this.title = 'Thêm mới phương tiện';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            //Mooc
            closeMooc(item){
                const selected = this.entity.moocs.findIndex(x => x.id == this.selectedId);
                this.entity.moocs[selected].id = item.id;
                this.entity.moocs[selected].vehicleCode = item.vehicleCode;
                this.entity.moocs[selected].licensePlate = item.licensePlate;
                this.$refs.moocList.close();
                this.selectedId = '';
            },

            openMooc(id){
                this.selectedId = id;
                this.$refs.moocList.open();
            },

            //Mooc
            delMooc(item){
                const index = this.entity.moocs.findIndex(x => x.id == item.id);
                this.entity.moocs.splice(index, 1);
            },

            addMooc(){
                let item = { id: 'id_' + common.getFakeId(), vehicleCode: '', licensePlate: ''};
                this.entity.moocs.push(item);
            },

            //Location
            closeLocation(item){
                this.locationName = item.locationName;
                this.entity.locationId = item.id;
                this.entity.location = item;
                this.$refs.locationList.close();
            },

            openLocation(){
                this.$refs.locationList.open(0);
            },

            getLocationSelected(item){
                this.entity.locationId = item.id;
                this.entity.location = item;
                this.locationName = item.locationName;
            },
            
            getLocationList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.locations = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Registry
            delRegistryRow(item){
                const index = this.entity.registryList.findIndex(x => x.id == item.id);
                this.entity.registryList.splice(index, 1);
            },

            addRegistryRow(){
                let item = { id: 'id_' + common.getFakeId(), vehicleId: 0, registryCode: '', registryFee: 0, expiryDate: null, registryDate: common.dateNow };
                this.entity.registryList.push(item);
            },

            //Insurance
            delInsuranceRow(item){
                const index = this.entity.insuranceList.findIndex(x => x.id == item.id);
                this.entity.insuranceList.splice(index, 1);
            },

            addInsuranceRow(){
                let item = { id: 'id_' + common.getFakeId(), vehicleId: 0, insuranceFee: 0, insuranceCode: '', expiryDate: null, registryDate: common.dateNow, typeCode: 1 };
                this.entity.insuranceList.push(item);
            },

            updateFile(files){
                this.entity.files = files;
            },

            getTypes(searchTerm){
                typeService.getTypes(this.groupInsurance, searchTerm).then((response) => {
                    if(response.statusCode == 200){
                        this.typeInsurance = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.tireList.length > 0){
                    this.entity.tireList = this.entity.tireList.filter(item => !(item.product.id == 0));
                    this.entity.tireList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                    for (let i = 0; i < this.entity.tireList.length; i++) {
                        this.entity.tireList[i].productId = this.entity.tireList[i].product.id;
                    }
                }
                if(this.entity.insuranceList.length > 0){
                    this.entity.insuranceList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.registryList.length > 0){
                    this.entity.registryList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.moocs.length > 0){
                    this.entity.moocs.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.entity.isVehicle = true;
                    this.add();
                }
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Child Group
            closeGroup(item){
                this.groupName = item.groupName;
                this.entity.groupId = item.id;
                this.$refs.childGroup.close();
            },

            openGroup(){
                this.$refs.childGroup.open(this.parentId);
            },

            getGroupSelected(val){
                this.entity.groupId = val.id;
                this.groupName = val.groupName;
            },

            getGroups(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, parentId: this.parentId, code: '', name:  searchTerm };
                vehicleGroupService.getGroups(search).then((response) => {
                    if(response.statusCode == 200){
                        this.groups = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Parent Group
            closeParentGroup(item){
                this.parentName = item.groupName;
                this.parentId = item.id;
                this.entity.groupId = 0;
                this.$refs.parentGroup.close();
            },

            getParentGroupSelected(val){
                this.parentId = val.id;
                this.parentName = val.groupName;
                this.entity.groupId = 0;
            },

            openParentGroup(){
                this.$refs.parentGroup.open(true);
            },

            getParentGroups(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, isVehicle: true, code: '', name:  searchTerm };
                vehicleGroupService.getParents(search).then((response) => {
                    if(response.statusCode == 200){
                        this.parentGroups = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Tire Position
            closeTireCode(item){
                const selected = this.entity.tireList.findIndex(x => x.id == this.selectedId);
                this.entity.tireList[selected].type = item;
                this.entity.tireList[selected].typeCode = item.code;
                this.$refs.tireCodeList.close();
                this.selectedId = '';
            },

            openTireCode(id){
                this.selectedId = id;
                this.$refs.tireCodeList.open();
            },

            //Tire
            delTireRow(item){
                const index = this.entity.tireList.findIndex(x => x.id == item.id);
                this.entity.tireList.splice(index, 1);
            },

            addTireRow(){
                let item = { id: 'id_' + common.getFakeId(), product: {id: 0, code: '' }, type: { name: '' }, typeCode: '', position: 0, createdDate: common.dateNow, tireCoe: ''};
                this.entity.tireList.push(item);
            },

            closeTire(item){
                const selected = this.entity.tireList.findIndex(x => x.id == this.selectedId);
                this.entity.tireList[selected].product = item;
                this.$refs.tireList.close();
                this.selectedId = '';
            },

            openTire(id){
                this.selectedId = id;
                this.$refs.tireList.open();
            },

            //Vehicle-Insurance
            openInsurance(){
                if(this.entity.id == 0){
                    this.dataCheck = true;
                    return;
                }
                this.$refs.insuranceCrud.open(this.entity);
            },

            closeInsurance(){
                this.$refs.insuranceList.refresh();
                this.$refs.insuranceCrud.close();
            },

            //vehicle-registry
            openRegistry(){
                if(this.entity.id == 0){
                    this.dataCheck = true;
                    return;
                }
                this.$refs.registryCrud.open(this.entity);
            },

            closeRegistry(){
                this.$refs.registryList.refresh();
                this.$refs.registryCrud.close();
            },

            //Owner
            closeOwner(item){
                this.ownerName = item.companyName;
                this.entity.ownerId = item.id;
                this.$refs.ownerList.close();
            },

            getOwnerSelected(val){
                this.entity.ownerId = val.id;
                this.ownerName = val.companyName;
            },

            openOwner(){
                if(!this.entity.isOwner){
                    this.$refs.ownerList.open();
                }
            },

            getOwners(searchTerm){
                if(this.entity.isOwner){
                    this.getOwner();
                }
                else{
                    let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.transport, code: searchTerm };
                    companyService.getCompanies(search).then((response) => {
                        if(response.statusCode == 200){
                            this.owners = response.data.items;
                        }
                        else{
                            messageBox.showWarning(response.data);
                        }
                    }).finally();
                }
            },

            //Brand
            closeBrand(item){
                this.brandName = item.brandName;
                this.entity.brandId = item.id;
                this.$refs.brandList.close();
            },

            getBrandSelected(val){
                this.entity.brandId = val.id;
                this.brandName = val.brandName;
            },

            openBrand(){
                this.$refs.brandList.open();
            },

            getBrands(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: '', name:  searchTerm };
                brandService.getBrands(search).then((response) => {
                    if(response.statusCode == 200){
                        this.brands = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            add(){
                this.setLoading(true);
                vehicleService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        if(!this.saveAndCreate){
                            this.$router.push('/vehicle');
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                vehicleService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/vehicle');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                vehicleService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.ownerName = this.entity.owner.companyAlias;
                        this.statusName = this.entity.type.name;
                        this.brandName = this.entity.brand.brandName;
                        this.parentName = this.entity.group.parent.groupName;
                        this.parentId = this.entity.group.parent.id;
                        this.groupName = this.entity.group.groupName;
                        if(this.entity.locationId > 0){
                            this.locationName = this.entity.location.locationName;
                        }
                        this.$refs.upload.loadData(this.entity.files);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/vehicle');
            },

            changeOwner(owner){
                if(owner){
                    this.getOwner();
                }
                else{
                    this.entity.ownerId = 0;
                    this.ownerName = '';
                }
            },

            getOwner(){
                companyService.getOwner().then((response) => {
                    if(response.statusCode == 200){
                        this.entity.ownerId = response.data.id;
                        this.ownerName = response.data.companyAlias;
                        this.owners = [];
                        this.owners.push(response.data);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            }
        },
        watch: { 
            groupName: function (val) { 
                if(val == ''){
                    this.entity.groupId = 0;
                }
            },
            parentName: function (val) { 
                if(val == ''){
                    this.parentId = 0;
                }
            },
            brandName: function (val) { 
                if(val == ''){
                    this.entity.brandId = 0;
                }
            },
            ownerName: function (val) { 
                if(val == ''){
                    this.entity.ownerId = 0;
                }
            },
            statusName: function (val) { 
                if(val == ''){
                    this.entity.status = '';
                }
            }
        },
        validations: {
            parentId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            entity: {
                groupId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                ownerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                branchId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                brandId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                vehicleCode: { required },
                licensePlate: { required },
                status: { required },
                registryList: {
                    $each: {
                        registryCode: { required },
                        registryDate: { required },
                        expiryDate: { required },
                    }
                },
                insuranceList: {
                    $each: {
                        insuranceCode: { required },
                        registryDate: { required },
                        expiryDate: { required },
                    }
                },
            }
       }
     })

</script>
